import React from 'react';

import SupportTicketScreen from 'screens/SupportTicketScreen/SupportTicketScreen';
import { ROUTE_CALCULATOR } from 'utils/constants/routes';

export const OfferScreen = () => (
  <SupportTicketScreen
    subject="book_offer_request"
    backTo={ROUTE_CALCULATOR}
    organizerOnly={true}
  />
);

export default OfferScreen;
