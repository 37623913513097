import React from 'react';

import SupportTicketScreen from 'screens/SupportTicketScreen/SupportTicketScreen';
import { ROUTE_SETTINGS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';

class DeleteStudentScreen extends React.Component<HistoryProps> {
  render() {
    return (
      <SupportTicketScreen
        subject="delete_student"
        backTo={ROUTE_SETTINGS + '/students'}
        organizerOnly={true}
        additionalParams={{ delete_profile_id: idFromMatch(this.props.match) }}
      />
    );
  }
}

export default DeleteStudentScreen;
