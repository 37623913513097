import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_BACK_COVER, ROUTE_LAYOUT } from 'utils/constants/routes';

import ChapterLayoutsScreen from './ChapterLayoutsScreen/ChapterLayoutsScreen';
import LayoutScreen from './LayoutScreen';
import BackCreativeScreen from './cover/BackCreativeScreen';
import BackDesignScreen from './cover/BackDesignScreen';
import BackHeadlineScreen from './cover/BackHeadlineScreen';
import BackSortingScreen from './cover/BackSortingScreen';
import BackSponsorScreen from './cover/BackSponsorScreen';
import CoverBackScreen from './cover/CoverBackScreen';
import CoverFrontScreen from './cover/CoverFrontScreen';
import CoverScreen from './cover/CoverScreen';
import SpineTextScreen from './cover/SpineTextScreen';
import AddCustomMottoScreen from './theme/AddCustomMottoScreen';
import ThemeDetailScreen from './theme/ThemeDetailScreen';
import ThemesListScreen from './theme/ThemesListScreen';

export default () => (
  <Switch>
    <Route path={ROUTE_LAYOUT} exact={true} component={LayoutScreen} />
    {/* <Route
      path={ROUTE_LAYOUT + '/request'}
      exact={true}
      component={CustomLayoutScreen}
    /> */}

    <Route path={ROUTE_LAYOUT + '/editor'} component={ChapterLayoutsScreen} />
    <Route path={ROUTE_LAYOUT + '/cover'} component={CoverScreen} />

    <Route
      path={ROUTE_LAYOUT + '/front'}
      exact={true}
      component={CoverFrontScreen}
    />

    <Route path={ROUTE_LAYOUT + '/spine'} component={SpineTextScreen} />

    <Route path={ROUTE_BACK_COVER + '/sponsor'} component={BackSponsorScreen} />
    <Route path={ROUTE_BACK_COVER + '/design'} component={BackDesignScreen} />
    <Route path={ROUTE_BACK_COVER + '/sorting'} component={BackSortingScreen} />

    <Route
      path={ROUTE_BACK_COVER + '/creative'}
      component={BackCreativeScreen}
    />
    <Route path={ROUTE_BACK_COVER + '/header'} component={BackHeadlineScreen} />
    <Route path={ROUTE_BACK_COVER} component={CoverBackScreen} />

    <Route
      path={ROUTE_LAYOUT + '/theme/add'}
      component={AddCustomMottoScreen}
    />
    <Route path={ROUTE_LAYOUT + '/theme/:key'} component={ThemeDetailScreen} />
    <Route path={ROUTE_LAYOUT + '/theme'} component={ThemesListScreen} />

    <NotFoundRoute />
  </Switch>
);
