import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { ROUTE_LAYOUT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';
import TeamCallContainer from 'containers/TeamCallContainer/TeamCallContainer';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';

interface AddCustomMottoScreenProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
  form: FormType;
}

interface AddCustomMottoScreenState {
  updateState?: 'loading' | 'error';
  success?: boolean;
}

@inject('applicationStore', 'chaptersStore')
@observer
class AddCustomMottoScreen extends React.Component<
AddCustomMottoScreenProps & HistoryProps,
AddCustomMottoScreenState
> {
  state: AddCustomMottoScreenState = {};

  componentDidMount() {
    this.initialize();
  }

  private initialize() {
    this.loadCover();
  }

  private async loadCover() {
    return await this.props.chaptersStore.getCoverChapter();
  }

  private submitMotto() {
    const { form } = this.props;

    const cover = this.props.chaptersStore.itemIfCover;
    if (!cover) {
      this.abort();
      return;
    }

    if (!form.values.requested_theme) {
      return;
    }

    this.sendThemeRequest(cover.id, form.values.requested_theme);
  }

  private abort() {
    this.props.history.replace(ROUTE_LAYOUT + '/theme');
  }

  private async sendThemeRequest(chapterId: number, motto?: string) {
    this.setState({
      updateState: 'loading',
      success: undefined
    });

    try {
      await this.props.chaptersStore.updateChapterSettings(chapterId, {
        requested_theme_type: 'motto', // in v3 there is only a motto that can be requested
        requested_theme: motto
        // requested_theme_key: key
      });

      this.setState({
        updateState: undefined,
        success: true
      });
    } catch (error: any) {
      this.setState({
        updateState: 'error'
      });
    }
  }

  private renderMottoForm() {
    const { form } = this.props;

    return this.renderDialogPage(
      <>
        <Headline.Large>
          <FormattedMessage id="add which motto" />
        </Headline.Large>

        <TextInputComponent
          name="requested_theme"
          label={<FormattedMessage id="Your motto" />}
          autoFocus={true}
          {...form.bindInput('requested_theme')}
        />

        {this.state.updateState === 'error' && <GenericErrorComponent />}

        <ButtonBlock
          background="PRIMARY"
          onClick={() => this.submitMotto()}
          disabled={!form.values.requested_theme}
        >
          <FormattedMessage id="Next" />
        </ButtonBlock>
      </>
    );
  }

  private renderSuccess() {
    return <TeamCallContainer backTo={ROUTE_LAYOUT} />;
  }

  private renderLoading() {
    return this.renderDialogPage(<LoadingOverlayComponent />);
  }

  private renderDialogPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <BackLink to={ROUTE_LAYOUT + '/theme'} />
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>
      </>
    );
  }

  render() {
    const { chaptersStore } = this.props;

    if (chaptersStore.isItemLoading || this.state.updateState === 'loading') {
      return this.renderLoading();
    }

    if (this.state.success) {
      return this.renderSuccess();
    }

    return this.renderMottoForm();
  }
}

export default (props: AddCustomMottoScreenProps) => {
  const form = useForm();
  // @ts-ignore
  return <AddCustomMottoScreen {...props} form={form} />;
};
