import config from 'config';
import { intl } from 'i18n';
import { flow, getEnv, Instance, types } from 'mobx-state-tree';
import { pushSuccessSignup } from 'utils/analytics';
import { assert } from 'utils/assert';
import ROLES from 'utils/constants/roles';
import {
  ROUTE_ACCOUNT_DASHBOARD,
  ROUTE_AUTH,
  ROUTE_AUTH_ERROR,
  ROUTE_BOOK,
  ROUTE_DASHBOARD,
  ROUTE_LOGIN,
  ROUTE_LOGIN_FAILED,
  ROUTE_LOGOUT,
  ROUTE_ONBOARDING,
  ROUTE_SET_GROUP,
  ROUTE_TEXTILE,
  ROUTE_UNCONFIRMED
} from 'utils/constants/routes';
import createMap, { createMapWithTransform } from 'utils/create-map';
import history from 'utils/history';
import { local } from 'utils/storage';
import createAuthStateModel from 'utils/store/createAuthStateModel';
import createBookModel from 'utils/store/createBookModel';
import createProfileModel from 'utils/store/createProfileModel';
import AccountModel, { createAccountModel } from './AccountModel';

import AuthStateModel from './AuthStateModel';
import BookModel, { BookModelType } from './BookModel';
import { ChapterModelType } from './ChapterModel';
import ClientStateModel, {
  ClientStates,
  createClientStateModel
} from './ClientStateModel';
import { DeliveryDateModelType } from './DeliveryDateModel';
import ProfileModel, { ProfileModelType } from './ProfileModel';
import { MinimalStoreEnv } from './StoreEnv';

type AppError =
  | 'unauthorized'
  | 'book_locked'
  | 'feature_locked'
  | 'unconfirmed';

const FlashMessageEnum = types.maybe(
  types.enumeration(['default', 'warning', 'error', 'success'])
);
type FlashMessage = Instance<typeof FlashMessageEnum>;

const updateCurrentUser = (self: any, result: any) => {
  if (!result) {
    throw new Error('Empty response from API');
  }
  if (!result.id) {
    throw new Error("Authenticated user's ID required");
  }

  self.currentUser = createProfileModel(result.profile);
  self.currentTextileProfile = undefined;

  let { book } = result;
  if (book) {
    if (result.groups?.length) {
      // verify process will send groups separately, so also include these groups here
      book = {
        ...book,
        groups: result.groups
      };
    }

    self.book = createBookModel(book);
  }

  if (result.profile && result.profile.client_states) {
    self.setClientStates(result.profile.client_states);
  }
};

const updateCurrentAccount = (self: any, result: any) => {
  if (!result) {
    throw new Error('Empty response from API');
  }
  if (!result.id) {
    throw new Error("Authenticated user's ID required");
  }

  self.currentUser = undefined;
  self.currentTextileProfile = undefined;
  self.textileOrder = undefined;
  self.book = undefined;

  self.currentAccount = createAccountModel(result);

  updateCurrentUser(self, result);
};

const isBookLocked = (
  book?: BookModelType,
  currentUser?: ProfileModelType
): boolean => {
  if (!book) {
    return false;
  }

  const { editing_state } = book;

  if (editing_state === 'lock_detected') {
    // received 'Book locked' error from server before
    return true;
  }

  if (editing_state === 'revising' || editing_state === 'preparing') {
    // check if organizer
    return (
      !currentUser ||
      (currentUser.role !== ROLES.ORGANIZER &&
        currentUser.role !== ROLES.MANAGER)
    );
  }

  if (editing_state !== 'active') {
    return true;
  }

  return false;
};

const ApplicationStore = types
  .model('ApplicationStore', {
    // authorization
    authState: types.maybe(AuthStateModel),
    currentUser: types.maybe(ProfileModel),
    profileLoadingState: types.maybe(types.string),
    currentAccount: types.maybe(AccountModel),
    hint: types.maybe(types.string),
    // book
    book: types.maybe(BookModel),
    bookLoadingState: types.maybe(types.string),
    // client states
    clientStates: types.maybe(types.map(ClientStateModel)),
    // flash message
    flashMessage: types.maybe(types.string),
    flashMessageHidden: types.maybe(types.boolean),
    flashMessageType: FlashMessageEnum,

    // help button
    helpSubject: types.maybe(types.string),
    helpHidden: types.maybe(types.boolean),
    // Sometimes a child component needs to hide help button but we do not want to
    // mess with helpHidden then as it will break the parent screen's help subject.
    helpHiddenByChild: types.maybe(types.boolean),

    formLoadingState: types.maybe(
      types.enumeration(['loading', 'success', 'error'])
    )
  })
  .volatile<{ formLoadingErrors: { [key: string]: string[] } | undefined }>(
    () => ({
      formLoadingErrors: undefined
    })
  )
  .actions((self) => {
    const login = flow(function* (mobileNumber: string, password?: string) {
      const { client } = getEnv<MinimalStoreEnv>(self);
      try {
        self.authState = createAuthStateModel({
          loading: 'login',
          mobileNumber,
          passwordRequired: !password ? false : true
        });

        const result: any = yield client.login(mobileNumber, password);

        if (!result) {
          throw new Error('No response from server.');
        }

        const sanitizedNumber = !result.mobile_number
          ? {}
          : { mobileNumber: result.mobile_number };

        switch (result.status) {
          case 'Password required':
            // need to show password form
            // as soon as there are no users without password left, we might merge both forms
            self.authState = createAuthStateModel({
              ...self.authState,
              ...sanitizedNumber,
              loading: undefined,
              passwordRequired: true
            });
            return;

          case 'Logged in':
            if (result.account) {
              updateCurrentAccount(self, result.account);
            }

            if (
              self.currentUser?.role !== ROLES.APPLICANT &&
              self.book?.hasActiveGroups &&
              !self.book.isLockedForPreview &&
              !self.currentUser?.group?.id &&
              !self.clientStates?.has(ClientStates.group_select_dismissed)
            ) {
              // user needs to select group
              history.push(ROUTE_SET_GROUP);
            } else {
              history.replace(ROUTE_ACCOUNT_DASHBOARD);
            }

            self.authState = undefined;
            return;

          case 'Token sent':
            // should be removed when all users have passwords
            self.authState = createAuthStateModel({
              ...sanitizedNumber,
              tokenRequested: 'login'
            });
            return;

          default:
            // this should not happen
            throw new Error('Unknown login state.');
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ApplicationStore | login', error);
        }

        if (client.isLockedError(error)) {
          // error 423 -> profile is not confirmed
          self.authState = createAuthStateModel({
            ...self.authState,
            loading: undefined,
            error: 'profile_unconfirmed'
          });
          return;
        }

        if (client.isFormError(error)) {
          // this handles 'number unknown' or 'password incorrect' as well as these are returned as form errors
          self.authState = createAuthStateModel({
            ...self.authState,
            loading: undefined
          });
          throw error;
        }

        // other error - something went wrong
        self.authState = createAuthStateModel({
          ...self.authState,
          loading: undefined,
          error: 'login_error'
        });
      }
    });

    const authenticate = flow(function* (
      identifier: string,
      textileOrder: string | null
    ) {
      const { client } = getEnv<MinimalStoreEnv>(self);
      try {
        self.authState = createAuthStateModel({
          loading: 'login'
        });

        const result: any = yield client.authenticate(identifier);

        if (!result) {
          throw new Error('No response from server.');
        }

        if (result.account) {
          updateCurrentAccount(self, result.account);
        }

        if (result.did_confirm && self.currentAccount) {
          // first login, signal confirmed number to analytics
          pushSuccessSignup(self.currentAccount?.mobile_number);
        }

        if (textileOrder !== null) {
          history.replace(ROUTE_TEXTILE + `/${textileOrder}`);
        } else {
          history.replace(ROUTE_BOOK);
        }

        self.authState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ApplicationStore | authenticate', error);
        }

        const code =
          client.isUnauthorized(error) || client.isNotFound(error)
            ? 'login_failed'
            : 'login_error';

        self.authState = createAuthStateModel({
          error: code
        });

        if (code === 'login_failed') {
          history.replace(ROUTE_LOGIN_FAILED);
        }
      }
    });

    const initiatePasswordReset = flow(function* (mobileNumber: string) {
      const { client } = getEnv<MinimalStoreEnv>(self);
      try {
        self.authState = createAuthStateModel({
          ...self.authState,
          loading: 'login',
          mobileNumber
        });

        const result: any = yield client.requestPasswordReset(mobileNumber);

        if (!result) {
          throw new Error('No response from server.');
        }

        self.authState = createAuthStateModel({
          ...self.authState,
          loading: undefined,
          tokenRequested:
            result.status === 'Verification sent' ? 'verify' : 'password'
        });
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ApplicationStore | initiatePasswordReset', error);
        }

        if (client.isFormError(error)) {
          self.authState = createAuthStateModel({
            ...self.authState,
            loading: undefined
          });
          throw error;
        }

        // other error - something went wrong
        self.authState = createAuthStateModel({
          ...self.authState,
          loading: undefined,
          error: 'login_error'
        });
      }
    });

    const checkPasswordResetToken = flow(function* (token: string) {
      const { client } = getEnv<MinimalStoreEnv>(self);
      try {
        self.authState = createAuthStateModel({
          loading: 'login'
        });

        yield client.verifyPasswordResetToken(token);

        self.authState = createAuthStateModel({
          loading: undefined
        });
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ApplicationStore | checkPasswordResetToken', error);
        }

        if (client.isNotFound(error)) {
          self.authState = createAuthStateModel({
            loading: undefined,
            error: 'token_invalid'
          });
          return;
        }

        self.authState = createAuthStateModel({
          loading: undefined,
          error: 'login_error'
        });
      }
    });

    const resetPassword = flow(function* (token: string, newPassword: string) {
      const { client } = getEnv<MinimalStoreEnv>(self);
      try {
        self.authState = createAuthStateModel({
          loading: 'login'
        });

        yield client.resetPassword(token, newPassword);

        self.authState = createAuthStateModel({
          passwordReset: true
        });
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ApplicationStore | resetPassword', error);
        }

        if (client.isNotFound(error)) {
          self.authState = createAuthStateModel({
            loading: undefined,
            error: 'token_invalid'
          });
          return;
        }

        if (client.isFormError(error)) {
          self.authState = createAuthStateModel({
            ...self.authState,
            loading: undefined
          });
          throw error;
        }

        self.authState = createAuthStateModel({
          loading: undefined,
          error: 'reset_error'
        });
      }
    });

    const resetAuthState = () => {
      self.authState = undefined;
      self.hint = undefined;
    };

    // TODO merge checkAuthenticated and refreshSession
    const checkAuthenticated = flow(function* (
      redirectToLogin = false,
      redirectToDashboard = false
    ) {
      const { client } = getEnv<MinimalStoreEnv>(self);
      try {
        self.authState = createAuthStateModel({
          loading: 'check'
        });

        const result = yield client.checkAuthenticated();

        if (!result) {
          // no user authenticated
          self.currentUser = undefined;
          self.currentAccount = undefined;
          self.authState = undefined;

          if (redirectToLogin) {
            history.push(ROUTE_LOGIN);
          }
          return false;
        }

        updateCurrentAccount(self, result);
        self.authState = undefined;

        if (redirectToDashboard) {
          history.push(ROUTE_ACCOUNT_DASHBOARD);
        }

        return true;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ApplicationStore | checkAuthenticated',
            error,
            error.response
          );
        }

        // TODO: Does this happen? When does this happen?
        // The client returns 404 when there is no session. This is caught in NuggitApi.ts.
        if (client.isUnauthorized(error)) {
          // no user authenticated
          self.currentUser = undefined;
          self.currentAccount = undefined;
          self.authState = undefined;

          if (redirectToLogin) {
            history.push(ROUTE_LOGIN);
          }
          return false;
        }

        if (client.lockedErrorType(error) === 'Account not confirmed') {
          // user needs to verify their number
          self.currentUser = undefined;
          self.currentAccount = undefined;
          self.authState = undefined;

          if (redirectToLogin) {
            history.push(ROUTE_UNCONFIRMED);
          }
          return false;
        }

        self.authState = createAuthStateModel({
          error: 'check_error'
        });

        if (redirectToLogin) {
          history.push(ROUTE_AUTH_ERROR);
        }

        return false;
      }
    });

    const checkForBookOnboarding = () => {
      if (
        self.book?.editing_state === 'onboarding' &&
        self.currentUser &&
        self.currentUser.role === ROLES.MANAGER
      ) {
        history.push(ROUTE_ONBOARDING + '/class');
        return true;
      }
    };

    const checkAuthenticatedBook = flow(function* (redirectToLogin = false) {
      const { client } = getEnv<MinimalStoreEnv>(self);
      try {
        self.authState = createAuthStateModel({
          loading: 'check'
        });

        const result = yield client.checkAuthenticated();

        if (!result) {
          // no user authenticated
          self.currentUser = undefined;
          self.currentAccount = undefined;
          self.authState = undefined;

          if (redirectToLogin) {
            history.push(ROUTE_LOGIN);
          }
          return false;
        }

        updateCurrentAccount(self, result);
        self.authState = undefined;

        if (!self.currentUser) {
          history.push(ROUTE_ACCOUNT_DASHBOARD);
        }

        checkForBookOnboarding();

        if (
          self.currentUser?.role !== ROLES.APPLICANT &&
          self.book?.hasActiveGroups &&
          !self.book.isLockedForPreview &&
          !self.currentUser?.group?.id &&
          !self.clientStates?.has(ClientStates.group_select_dismissed)
        ) {
          // user needs to select group
          history.push(ROUTE_SET_GROUP);
          return true;
        }

        return true;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ApplicationStore | checkAuthenticatedTextileOrder',
            error,
            error.response
          );
        }

        // TODO: Does this happen? When does this happen?
        // The client returns 404 when there is no session. This is caught in NuggitApi.ts.
        if (client.isUnauthorized(error)) {
          // no user authenticated
          self.currentUser = undefined;
          self.currentAccount = undefined;
          self.authState = undefined;

          if (redirectToLogin) {
            history.push(ROUTE_LOGIN);
          }
          return false;
        }

        if (client.lockedErrorType(error) === 'Account not confirmed') {
          // user needs to verify their number
          self.currentUser = undefined;
          self.currentAccount = undefined;
          self.authState = undefined;

          if (redirectToLogin) {
            history.push(ROUTE_UNCONFIRMED);
          }
          return false;
        }

        self.authState = createAuthStateModel({
          error: 'check_error'
        });

        if (redirectToLogin) {
          history.push(ROUTE_AUTH_ERROR);
        }

        return false;
      }
    });

    const refreshSession = flow(function* (redirectToLogin: boolean = true) {
      const { client } = getEnv<MinimalStoreEnv>(self);
      try {
        self.authState = createAuthStateModel({
          loading: 'refresh'
        });

        const result = yield client.checkAuthenticated();

        if (!result) {
          // no user authenticated
          self.currentUser = undefined;
          self.currentAccount = undefined;
          self.authState = undefined;

          if (redirectToLogin) {
            history.push(ROUTE_LOGIN);
          }
          return;
        }

        updateCurrentAccount(self, result);
        self.authState = undefined;

        if (
          self.currentUser?.role !== ROLES.APPLICANT &&
          self.book?.hasActiveGroups &&
          !self.book.isLockedForPreview &&
          !self.currentUser?.group?.id &&
          !self.clientStates?.has(ClientStates.group_select_dismissed)
        ) {
          // user needs to select group
          history.push(ROUTE_SET_GROUP);
          return;
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ApplicationStore | refreshSession',
            error,
            error.response
          );
        }

        if (client.isUnauthorized(error)) {
          // no user authenticated
          self.currentUser = undefined;
          self.currentAccount = undefined;
          self.authState = undefined;

          if (redirectToLogin) {
            history.push(ROUTE_LOGIN);
          }
          return;
        }

        if (client.lockedErrorType(error) === 'Profile not confirmed') {
          // user needs to verify their number
          self.currentUser = undefined;
          self.currentAccount = undefined;
          self.authState = undefined;

          if (redirectToLogin) {
            history.push(ROUTE_UNCONFIRMED);
          }
          return;
        }

        self.authState = createAuthStateModel({
          error: 'refresh_error'
        });
      }
    });

    const logout = flow(function* (redirectAfterLogout = true) {
      const { client } = getEnv<MinimalStoreEnv>(self);
      try {
        self.authState = createAuthStateModel({
          loading: 'logout'
        });

        yield client.logout();

        self.currentUser = undefined;
        self.currentAccount = undefined;
        self.clientStates = undefined;
        self.authState = undefined;

        clearFlashMessage();

        if (redirectAfterLogout) {
          history.push(ROUTE_LOGOUT);
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ApplicationStore | logout', error);
        }

        self.authState = createAuthStateModel({
          error: 'logout_error'
        });

        if (redirectAfterLogout) {
          history.push(ROUTE_AUTH_ERROR);
        }
      }
    });

    // Returns AppErrorEnum value if the error has been handled and the calling store only needs to sort out its state properly.
    const handleAppError = (
      error: any,
      skipBookLockedHandling = false
    ): AppError | null => {
      const { client } = getEnv<MinimalStoreEnv>(self);

      if (client.isUnauthorized(error)) {
        // TODO We might need to check if unauthorized means "access denied" or "not logged in";
        //      maybe the server should use different error codes?
        history.push(ROUTE_LOGIN);
        return 'unauthorized';
      }

      const lockedError = client.lockedErrorType(error);
      switch (lockedError) {
        case 'Book locked':
          setBookLocked();

          if (!skipBookLockedHandling) {
            setFlashMessage(
              intl.formatMessage({ id: 'book locked error' }),
              'error'
            );
            history.push(ROUTE_DASHBOARD);
          }
          return 'book_locked';

        case 'Feature locked':
          setFlashMessage(
            intl.formatMessage({ id: 'feature locked error' }),
            'error'
          );
          history.push(ROUTE_DASHBOARD);
          return 'feature_locked';

        case 'Profile not confirmed':
          history.push(ROUTE_UNCONFIRMED);
          return 'unconfirmed';
      }

      return null;
    };

    const getBook = flow(function* (clear: boolean = false) {
      const { client } = getEnv<MinimalStoreEnv>(self);

      let updating = false;
      try {
        // getBook() will only update book by default instead of refreshing it entirely
        if (clear || !self.book) {
          self.bookLoadingState = 'loading';
          self.book = undefined;
        } else {
          updating = true;
          self.bookLoadingState = 'updating';
        }

        const book = yield client.getBook();

        self.book = createBookModel(book);
        self.bookLoadingState = undefined;

        return self.book;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ApplicationStore | getBook', error, error.body);
        }

        if (handleAppError(error)) {
          self.bookLoadingState = undefined;
          return;
        }

        self.bookLoadingState = updating ? 'update_error' : 'error';
      }
    });

    const setBookLocked = () => {
      if (!self.book) {
        // TODO Handle this in a better way?
        return;
      }
      self.book.editing_state = 'lock_detected';
    };

    const setBookOperatingNormally = () => {
      if (!self.book) {
        // TODO Handle this in a better way?
        return;
      }
      self.book.editing_state = 'active';
    };

    const setBook = (book: BookModelType) => {
      self.book = createBookModel(book);
    };

    const setBookEnableMottoVote = flow(function* (
      enable: boolean,
      updateBook: boolean = true
    ) {
      const { client } = getEnv<MinimalStoreEnv>(self);

      try {
        yield client.setAllowMottoVote(enable);

        if (updateBook && self.book) {
          self.book.allow_motto_vote = enable;
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ApplicationStore | setBookEnableMottoVote',
            error,
            error.body
          );
        }

        if (handleAppError(error)) {
          self.bookLoadingState = undefined;
          return;
        }

        throw new Error('save_error');
      }
    });

    const requestDesignTicket = flow(function* (updateBook: boolean = true) {
      const { client } = getEnv<MinimalStoreEnv>(self);

      try {
        // self.bookLoadingState = 'loading';

        const book: any = yield client.requestDesignTicket();

        if (updateBook && book) {
          setBook(book);
        }

        // self.bookLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ApplicationStore | requestDesignTicket',
            error,
            error.body
          );
        }

        if (handleAppError(error)) {
          // self.bookLoadingState = undefined;
          return;
        }

        // self.bookLoadingState = undefined;
        throw error;
      }
    });

    const applyGroups = flow(function* (updateBook: boolean = true) {
      const { client } = getEnv<MinimalStoreEnv>(self);

      try {
        // self.bookLoadingState = 'loading';

        const book: any = yield client.applyGroups();

        if (updateBook && book) {
          setBook(book);
        }

        // self.bookLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ApplicationStore | applyGroups', error, error.body);
        }

        if (handleAppError(error)) {
          // self.bookLoadingState = undefined;
          return;
        }

        // self.bookLoadingState = undefined;
        throw error;
      }
    });

    const setClientStates = (clientStates: any[]) => {
      self.clientStates = createMapWithTransform(
        clientStates,
        createClientStateModel,
        'identifier'
      );
    };

    const updateClientState = flow(function* (
      identifier: string,
      value: string | undefined | null,
      forceUpdate: boolean = true,
      callSupport: boolean = false,
      supportMessage?: string,
      supportSubject?: string
    ) {
      const { client } = getEnv<MinimalStoreEnv>(self);

      try {
        const clientState = createClientStateModel(
          yield client.setClientState(
            identifier,
            value,
            callSupport,
            supportMessage,
            supportSubject
          )
        );

        if (clientState || forceUpdate) {
          if (!self.clientStates) {
            self.clientStates = createMap({});
          }

          self.clientStates!.put(
            clientState ||
              createClientStateModel({
                identifier,
                value
              })!
          );
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ApplicationStore | updateClientState',
            error,
            error.body
          );
        }

        if (handleAppError(error)) {
          return;
        }

        throw new Error('save_error');
      }
    });

    const overrideLocalClientState = (
      identifier: string,
      value: string | undefined | null
    ) => {
      if (!self.clientStates) {
        self.clientStates = createMap({});
      }

      self.clientStates!.put(
        createClientStateModel({
          identifier,
          value
        })!
      );
    };

    const deleteClientState = flow(function* (identifier: string) {
      const { client } = getEnv<MinimalStoreEnv>(self);

      try {
        yield client.removeClientState(identifier);

        self.clientStates?.delete(identifier);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ApplicationStore | deleteClientState',
            error,
            error.body
          );
        }

        if (handleAppError(error)) {
          return;
        }

        throw new Error('save_error');
      }
    });

    const joinFeature = (feature: string) => {
      if (
        feature === 'join_book_role_select' &&
        !self.clientStates?.has('join_book_role_select')
      ) {
        history.replace(ROUTE_AUTH + '/join_book/');
        return true;
      }

      return false;
    };

    const onboardFeature = (feature: string, backRoute?: string) => {
      if (!self.clientStates?.has('ob_' + feature)) {
        history.replace(
          ROUTE_AUTH + '/onboard/' + feature,
          backRoute ? { backRoute } : undefined
        );
        return true;
      }

      return false;
    };

    const createSupportTicket = flow(function* (
      subject?: string,
      additionalParams?: any
    ) {
      const { client } = getEnv<MinimalStoreEnv>(self);

      try {
        yield client.createSupportTicket(subject, additionalParams);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ApplicationStore | createSupportTicket',
            error,
            error.body
          );
        }

        if (handleAppError(error)) {
          return;
        }

        throw new Error('save_error');
      }
    });

    const setClassSize = flow(function* (
      size?: number,
      updateBook: boolean = true
    ) {
      const { client } = getEnv<MinimalStoreEnv>(self);

      try {
        self.bookLoadingState = 'loading';

        yield client.setClassSize(size);

        if (updateBook && self.book) {
          self.book.number_of_students = size || undefined;
        }

        self.bookLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ApplicationStore | setClassSize', error, error.body);
        }

        if (handleAppError(error)) {
          self.bookLoadingState = undefined;
          return;
        }

        self.bookLoadingState = 'error';
      }
    });

    const completeOnboarding = flow(function* () {
      const { client } = getEnv<MinimalStoreEnv>(self);

      try {
        self.bookLoadingState = 'loading';

        yield client.completeOnbaording();

        if (self.book) {
          self.book.editing_state = 'active';
        }

        self.bookLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ApplicationStore | setBookState', error, error.body);
        }

        if (handleAppError(error)) {
          self.bookLoadingState = undefined;
          return;
        }

        self.bookLoadingState = undefined;
        throw error;
      }
    });

    // -v2-
    // TODO Remove or keep? (In case it might be required again later.)
    const setBookState = flow(function* (
      state: 'preparing' | 'active' | 'revising',
      updateBook: boolean = true
    ) {
      const { client } = getEnv<MinimalStoreEnv>(self);

      try {
        self.bookLoadingState = 'loading';

        yield client.setBookState(state);

        if (updateBook && self.book) {
          self.book.editing_state = state;
        }

        self.bookLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ApplicationStore | setBookState', error, error.body);
        }

        if (handleAppError(error)) {
          self.bookLoadingState = undefined;
          return;
        }

        self.bookLoadingState = undefined;
        throw error;
      }
    });

    const setTimePlan = flow(function* (deliveryDate: DeliveryDateModelType) {
      const { client } = getEnv<MinimalStoreEnv>(self);

      assert(self.book);

      const date = deliveryDate.delivery_date;

      try {
        yield client.setTimePlan(date);

        self.book.time_plan = date;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ApplicationStore | chooseTimePlan', error, error.body);
        }

        if (handleAppError(error)) {
          return undefined;
        }

        throw error;
      }
    });

    const generateBookPreview = flow(function* (updateBook: boolean = true) {
      const { client } = getEnv<MinimalStoreEnv>(self);

      try {
        yield client.generateBookPreview();

        if (updateBook && self.book) {
          self.book.editing_state = 'generating';
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ApplicationStore | generateBookPreview',
            error,
            error.body
          );
        }

        if (handleAppError(error)) {
          return;
        }

        throw error;
      }
    });

    const dismissBookPreview = flow(function* (updateBook: boolean = true) {
      const { client } = getEnv<MinimalStoreEnv>(self);

      try {
        yield client.dismissBookPreview();

        if (updateBook && self.book) {
          self.book.editing_state = 'revising';
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ApplicationStore | dismissBookPreview',
            error,
            error.body
          );
        }

        if (handleAppError(error)) {
          return;
        }

        throw error;
      }
    });

    const patchCurrentUser = (patch: any) => {
      if (!self.currentUser || patch.id !== self.currentUser.id) {
        return;
      }

      self.currentUser = createProfileModel({
        ...self.currentUser,
        ...patch
      });
    };

    const patchCurrentAccount = (patch: any) => {
      if (!self.currentAccount) {
        return;
      }

      self.currentAccount = createAccountModel({
        ...self.currentAccount,
        ...patch
      });
    };

    const setFlashMessage = (message?: string, type?: FlashMessage) => {
      self.flashMessageHidden = undefined;
      self.flashMessage = message || undefined;
      self.flashMessageType = type || undefined;
    };

    const hideFlashMessage = () => {
      self.flashMessageHidden = true;
    };

    const clearFlashMessage = () => {
      setFlashMessage();
    };

    const setDefaultFlashMessage = (type: 'saved' | 'save_error') => {
      let flashType: FlashMessage = 'default';
      let msgId: string | undefined;

      switch (type) {
        case 'saved':
          msgId = 'change saved';
          break;

        case 'save_error':
          msgId = 'change save error';
          flashType = 'error';
          break;

        default:
      }

      if (msgId) {
        setFlashMessage(intl.formatMessage({ id: msgId }), flashType);
      }
    };

    const setHelp = (subject?: string, hidden?: boolean) => {
      self.helpSubject = subject || undefined;
      self.helpHidden = hidden || undefined;
    };

    const setHelpHiddenByChild = (hidden?: boolean) => {
      self.helpHiddenByChild = hidden || undefined;
    };

    const submitFunnel = flow(function* (
      formData: {
        [key: string]: string | string[] | number | number[] | boolean;
      },
      textileOrderId?: string
    ) {
      const { client } = getEnv<MinimalStoreEnv>(self);
      self.formLoadingState = 'loading';

      const funnelRef = local.get(config.signupRefName);
      if (!!funnelRef) {
        formData['funnel_ref'] = funnelRef;
      }

      if (textileOrderId) {
        formData['textile_order_id'] = textileOrderId;
      }

      try {
        yield client.submitFunnel(formData);

        self.formLoadingState = 'success';
      } catch (error: any) {
        self.formLoadingState = 'error';

        if (process.env.NODE_ENV !== 'production') {
          console.error('ApplicationStore | submitFunnel', error, error.body);
        }

        throw error;
      }
    });

    return {
      login,
      authenticate,
      checkAuthenticated,
      checkAuthenticatedBook,
      refreshSession,

      initiatePasswordReset,
      checkPasswordResetToken,
      resetPassword,

      resetAuthState,
      logout,

      handleAppError,

      getBook,
      setBookEnableMottoVote,
      setClassSize,
      setBookState,
      setTimePlan,
      completeOnboarding,

      setBook,
      setBookLocked,
      setBookOperatingNormally,
      checkForBookOnboarding,

      setClientStates,
      updateClientState,
      overrideLocalClientState,
      deleteClientState,
      joinFeature,
      onboardFeature,
      createSupportTicket,

      generateBookPreview,
      dismissBookPreview,
      requestDesignTicket,
      applyGroups,

      patchCurrentUser,
      patchCurrentAccount,

      setFlashMessage,
      hideFlashMessage,
      clearFlashMessage,
      setDefaultFlashMessage,

      setHelp,
      setHelpHiddenByChild,

      submitFunnel
    };
  })
  .views((self) => {
    return {
      get isAuthenticating(): boolean {
        return self.authState &&
          self.authState.loading &&
          self.authState.loading !== 'refresh'
          ? true
          : false;
      },
      get isTokenRequested(): boolean {
        return !self.authState || !self.authState.tokenRequested ? false : true;
      },
      get isPasswordReset(): boolean {
        return !self.authState || !self.authState.passwordReset ? false : true;
      },
      get isAuthenticated(): boolean {
        return !self.currentAccount ? false : true;
      },
      get isLoginError(): boolean {
        return self.authState && self.authState.error === 'login_error'
          ? true
          : false;
      },
      get isPasswordResetError(): boolean {
        return self.authState && self.authState.error === 'reset_error'
          ? true
          : false;
      },
      get isAuthenticationError(): boolean {
        return !self.authState || !self.authState.error ? false : true;
      },
      get isRefreshing(): boolean {
        return self.authState && self.authState.loading === 'refresh'
          ? true
          : false;
      },
      get isRefreshError(): boolean {
        return self.authState && self.authState.error === 'refresh_error'
          ? true
          : false;
      },
      get isOrganizer(): boolean {
        return self.currentUser &&
          (self.currentUser.role === ROLES.ORGANIZER ||
            self.currentUser.role === ROLES.MANAGER)
          ? true
          : false;
      },
      get isManager(): boolean {
        return self.currentUser?.role === ROLES.MANAGER;
      },
      get isApplicant(): boolean {
        return !self.currentUser || self.currentUser.role === ROLES.APPLICANT
          ? true
          : false;
      },
      get isCreatedManually(): boolean {
        return !self.currentUser ||
          self.currentUser.role === ROLES.MANUALLY_CREATED_STUDENT ||
          self.currentUser.role === ROLES.MANUALLY_CREATED_ORGANIZER
          ? true
          : false;
      },
      get atLeastStudent(): boolean {
        if (!self.currentUser) {
          return false;
        }
        return self.currentUser.role === ROLES.STUDENT ||
          self.currentUser.role === ROLES.ORGANIZER ||
          self.currentUser.role === ROLES.MANAGER
          ? true
          : false;
      },
      get currentUserId(): number {
        return !self.currentUser ? 0 : self.currentUser.id;
      },
      get currentTextileUserId(): number {
        return !self.currentUser ? 0 : self.currentUser.id;
      },

      get isBookLoading(): boolean {
        return self.bookLoadingState === 'loading' ||
          self.bookLoadingState === 'updating'
          ? true
          : false;
      },
      get isBookError(): boolean {
        return self.bookLoadingState === 'error' ||
          self.bookLoadingState === 'update_error'
          ? true
          : false;
      },

      clientState(identifier: string): string | undefined {
        return self.clientStates?.get(identifier)?.value || undefined;
      },
      onboardState(feature: string): string | undefined {
        return this.clientState('ob_' + feature);
      },

      get isBookLocked(): boolean {
        return isBookLocked(self.book, self.currentUser);
      },
      // get isBookLockedForStudents(): boolean {
      //   if (self.book?.editing_state === 'active') {
      //     return false;
      //   }
      //   return true;
      // },
      get isBookPreparingOrActive(): boolean {
        const state = self.book?.editing_state;
        return state === 'active' || state === 'preparing';
      },
      get hasBookMotto(): boolean {
        return self.book && self.book.motto ? true : false;
      },
      get isBookMottoVotingEnabled(): boolean {
        return self.book && self.book.allow_motto_vote
          ? self.book.allow_motto_vote
          : false;
      },

      get isEditAllowed(): boolean {
        return !this.isBookLocked;
      },

      isChapterEditAllowed(chapter?: ChapterModelType): boolean {
        if (this.isBookLocked) {
          return false;
        }

        if (!chapter) {
          return false;
        }

        if (chapter.editing_state === 'closed') {
          return this.isOrganizer;
        }

        return true;
      },
      isChapterOrBookLockedForStudents(chapter?: ChapterModelType): boolean {
        if (self.book?.editing_state !== 'active') {
          return true;
        }
        return chapter?.editing_state === 'closed';
      }
    };
  });

export type ApplicationStoreType = Instance<typeof ApplicationStore>;
export default ApplicationStore;
